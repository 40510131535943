import React from "react";
import { Link } from "gatsby";

import "../scss/caseStudy.scss";

class Casestudy extends React.Component {
  render() {
    return (
      <div className="case-study-component">
        <div className="col-one">
          <h6 className="col-one-subheading">
            <span className="line"></span>
            {this.props.casestudytype}
          </h6>
          <h3 className="col-one-heading">
            {this.props.casestudytitle}
          </h3>
          <p className="col-one-content">
            {this.props.casestudycontent}
          </p>
          <Link
            to={this.props.casestudylink}
            className="underline-link uppercase-link"
            title={this.props.casestudytype}
          >
            View More
          </Link>
        </div>
        <div className="col-two">
          <Link
            to={this.props.casestudylink}
            className=""
            title={this.props.casestudytype}
          >
            <img alt="" src={this.props.casestudyimage}/>
          </Link>
        </div>
      </div>
    )
  }
}

export default Casestudy
