import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import Casestudy from "../components/casestudy"
import casestudy1image from "../images/case-studies/australian-vitamins-00-hero.jpg"
import casestudy2image from "../images/case-studies/le-buns-00-hero.jpg"
import casestudy3image from "../images/case-studies/stratton-finance-00-hero.jpg"
import casestudy4image from "../images/case-studies/sesame-street-00-hero.jpg"
import casestudy5image from "../images/case-studies/get-wines-00-hero.jpg"
import casestudy6image from "../images/case-studies/dunlopdiy-00-hero.jpg"
import casestudy7image from "../images/case-studies/center-for-u-00-hero.jpg"
import casestudy8image from "../images/case-studies/crt-00-hero.jpg"
import casestudy9image from "../images/case-studies/transclean-00-hero.jpg"
import casestudy10image from "../images/case-studies/transdev-00-hero.jpg"
import casestudy11image from "../images/case-studies/merck-sharp-and-dohme-00-hero.jpg"
import casestudy12image from "../images/case-studies/campbell-page-00-hero.jpg"
import casestudy13image from "../images/case-studies/ardex-global-00-hero.jpg"
import casestudy14image from "../images/case-studies/icare-00-hero.jpg"
import casestudy15image from "../images/case-studies/maven-digital-00-hero.jpg"
import casestudy16image from "../images/case-studies/pearson-00-hero.jpg"

import "../scss/caseStudies.scss"

const Casestudies = ({ data }) => {
    const metadescription =
          "Next&Co are an independent SEO Agency specialising in audits, visit our site to find out your search and market trends and how to build a roadmap to success."
    const metatitle = "Case Studies - Next&Co"
    const metaurl = typeof window !== "undefined" ? window.location.href : ""

    return (
        <>
            <Helmet>
                <title>{metatitle}</title>
                <meta name="description" content={metadescription} />
                <meta name="title" content={metatitle} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={metaurl} />
                <meta property="og:title" content={metatitle} />
                <meta property="og:description" content={metadescription} />
                <meta property="og:image" content="facebook.png" />
                <meta property="twitter:url" content={metaurl} />
                <meta property="twitter:title" content={metatitle} />
                <meta property="twitter:description" content={metadescription} />
                <meta property="twitter:image" content="twitter.png" />
            </Helmet>
            <div className="case-studies-page">
                <Layout>
                    <section id="row-one">
                        <div className="outer-container">
                            <div className="container-max-width">
                                <div className="inner-container">
                                    <div className="col-one">
                                        <h1>Case Studies</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="outer-container">
                            <div className="container-max-width">
                                <div className="inner-container">
		                    <Casestudy
			                casestudyimage={casestudy14image}
			                casestudytype="Digital Media"
			                casestudytitle="icare"
			                casestudycontent="Boosting and attributing offline sales to strategic paid media through smart location marketing."
			                casestudylink="/case-studies/icare"
		                    />

                                    <Casestudy
			                casestudyimage={casestudy16image}
			                casestudytype="Experience"
			                casestudytitle="Pearson"
			                casestudycontent="Pearson is the world's leading learning company providing education solutions to educators and students across all ages, interests and specialisations. In Australia and worldwide, the Pearson brand is well recognised and respected. "
			                casestudylink="/case-studies/pearson"
		                    />
                                    
		                    <Casestudy
			                casestudyimage={casestudy15image}
			                casestudytype="Experience"
			                casestudytitle="Maven Dental"
			                casestudycontent="Maven Dental is Australia's largest non-insurance based dental support organisation. With more than 100 locations across the country and the backing of their parent company, Abano Healthcare Group, Maven Dental's brand already had a strong market presence."
			                casestudylink="/case-studies/maven-dental"
		                    />
                                    
                                    <Casestudy
                                        casestudyimage={casestudy12image}
                                        casestudytype="Web Development"
                                        casestudytitle="Campbell Page"
                                        casestudycontent="Boosting brand and online performance to build better communities."
                                        casestudylink="/case-studies/campbell-page"
                                    />
                                    <Casestudy
			                casestudyimage={casestudy13image}
			                casestudytype="Website design and development"
			                casestudytitle="Ardex Global"
			                casestudycontent="Ardex Group is a leader in their market, providing solutions and services for innovative building systems."
			                casestudylink="/case-studies/ardex-global"
                                    />
                                    <Casestudy
                                        casestudyimage={casestudy11image}
                                        casestudytype="Social Media"
                                        casestudytitle="Merck Sharp & Dohme"
                                        casestudycontent="Boosting organic traffic, conversions and new customers engage Australia's GPs."
                                        casestudylink="/case-studies/merck-sharp-and-dohme"
                                    />
                                    <Casestudy
                                        casestudyimage={casestudy10image}
                                        casestudytype="Social Media"
                                        casestudytitle="Transdev"
                                        casestudycontent="Connecting rural retailers to their regional customer base."
                                        casestudylink="/case-studies/transdev"
                                    />
                                    <Casestudy
                                        casestudyimage={casestudy8image}
                                        casestudytype="App Development"
                                        casestudytitle="CRT"
                                        casestudycontent="Connecting rural retailers to their regional customer base."
                                        casestudylink="/case-studies/crt"
                                    />
                                    <Casestudy
                                        casestudyimage={casestudy7image}
                                        casestudytype="App Development"
                                        casestudytitle="Center For U"
                                        casestudycontent="Building health and wellness via intelligent mobile app design."
                                        casestudylink="/case-studies/center-for-u"
                                    />
                                    <Casestudy
                                        casestudyimage={casestudy6image}
                                        casestudytype="Web Development"
                                        casestudytitle="DunlopDIY"
                                        casestudycontent="Empowering a DIY supplier with a website they can manage themselves."
                                        casestudylink="/case-studies/dunlopdiy"
                                    />
                                    <Casestudy
                                        casestudyimage={casestudy5image}
                                        casestudytype="Search Engine Optimisation"
                                        casestudytitle="Get Wines Direct"
                                        casestudycontent=" Get Wines Direct is a pioneering online wine retailer delivering its customers some of the best drops from Australia and around the world."
                                        casestudylink="/case-studies/get-wines"
                                    />
                                    <Casestudy
                                        casestudyimage={casestudy1image}
                                        casestudytype="Digital Media"
                                        casestudytitle="Australian Vitamins"
                                        casestudycontent=" Australian Vitamins is a long-standing premium online health food brand with an extremely loyal customer base and high-quality Australian products which speak for themselves."
                                        casestudylink="/case-studies/australian-vitamins"
                                    />
                                    <Casestudy
                                        casestudyimage={casestudy2image}
                                        casestudytype="Web Development"
                                        casestudytitle="Le Buns"
                                        casestudycontent="Le Buns is an up and coming Australian swimwear label that puts sustainability and environmental consciousness at the centre of what they do."
                                        casestudylink="/case-studies/le-buns"
                                    />
                                    <Casestudy
                                        casestudyimage={casestudy3image}
                                        casestudytype="Search Engine Optimisation"
                                        casestudytitle="Stratton Finance"
                                        casestudycontent="Stratton Finance are the leading car finance website in Australia, assisting Australians with buying their new car."
                                        casestudylink="/case-studies/stratton-finance"
                                    />
                                    <Casestudy
                                        casestudyimage={casestudy4image}
                                        casestudytype="Digital Media Buying"
                                        casestudytitle="Sesame Street"
                                        casestudycontent="With an abundance of iconic characters instantly recognisable across multiple generations."
                                        casestudylink="/case-studies/sesame-street"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </Layout>
            </div>
        </>
    )
}

export default Casestudies
